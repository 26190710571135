import { useEffect, useContext } from 'react'
import { InferGetServerSidePropsType, GetServerSideProps } from 'next'
import { CarouselCourses, MainPublic } from '~/components/index'
import { PortalModal } from '@provi/provi-components'
import { ModalContainer } from '~/components/templates/ModalContainer'
import { CoursesContext } from '~/contexts/CoursesContext'
import { useRouter } from 'next/router'
import { handleServerSideProps } from '~/services/handleServerSideProps'
import { CheckoutContext } from '~/contexts/CheckoutContext'
import { ParamsContext } from '~/contexts/ParamsContext'

export const getServerSideProps: GetServerSideProps = async (context) => {
  const response = await handleServerSideProps(context)

  return response
}

export default function Courses({
  partnerData,
  coursesData,
  redirectUrlFromV2ToV3,
}: InferGetServerSidePropsType<typeof getServerSideProps>) {
  const { closeModal, modalRef, openModal } = useContext(CoursesContext)
  const { setStep, step } = useContext(CheckoutContext)
  const { route, mutateCart } = useContext(ParamsContext)
  const router = useRouter()
  const stepsWithSuccessStatus = [
    'checkoutSuccess',
    'financingSuccess',
    'incompleteBasicInfoAnalysis',
    'waitingGuarantorSignature',
    'creditInAnalysis',
  ].includes(step)

  useEffect(() => {
    if (typeof coursesData.isValidCampaign != undefined && coursesData.isValidCampaign === false) {
      openModal()
    }

    if (redirectUrlFromV2ToV3) {
      router.push(redirectUrlFromV2ToV3)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (stepsWithSuccessStatus && route === '/courses/[slug]') {
      setStep('preRegistration')
      sessionStorage.removeItem('user_token')
      mutateCart()
    }
  }, [route, setStep, stepsWithSuccessStatus, mutateCart])

  return (
    <MainPublic title={partnerData?.partnerName || ''} ssrPartnerData={partnerData}>
      <>
        <CarouselCourses coursesData={coursesData} />
        <PortalModal ref={modalRef} borderRadius={'8px'}>
          <ModalContainer closeModal={closeModal} partnerName={partnerData?.partnerName || ''} />
        </PortalModal>
      </>
    </MainPublic>
  )
}
